import { createRouter, createWebHistory } from "vue-router";
import TokenService from "../services/token.service";

const routes = [
  {
    path: "/",
    name: "Root",
    redirect: "/login",
  },

  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },

  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },

  {
    path: "/reset-password",
    name: "Request Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ "../views/Password/ForgotPassword.vue"
      ),
  },

  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/Password/ResetPassword.vue"
      ),
  },

  {
    path: "/magic-auth/:token",
    name: "Magic Link Authentication",
    component: () =>
      import(
        /* webpackChunkName: "magicAuth" */ "../views/MagicLink/Index.vue"
      ),
  },

  {
    path: "/:catchAll(.*)",
    name: "Page Not Found",
    component: () =>
      import(
        /* webpackChunkName: "pageNotFound" */ "../views/PageNotFound.vue"
      ),
  },

  {
    path: "/admin",
    name: "Admin View",
    component: () =>
      import(/* webpackChunkName: "adminPages" */ "../views/Admin/Index.vue"),
    meta: { requiresAuth: true, role: "admin" },
    children: [
      {
        path: "dashboard",
        name: "Administrator Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "adminDashboard" */ "../views/Admin/Dashboard.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "manage-user-accounts",
        name: "Manage User Accounts",
        component: () =>
          import(
            /* webpackChunkName: "manageUserAccounts" */ "../views/Admin/ManageUserAccounts.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "purchase-pipeline",
        name: "Purchase Pipeline" + " ",
        component: () =>
          import(
            /* webpackChunkName: "adminPurchasePipeline" */ "../views/Admin/PurchasePipeline.vue"
          ),
        meta: { requiresAuth: true, role: "admin", role: "admin" },
      },
      {
        path: "bank-account",
        name: "Bank Account",
        component: () =>
          import(
            /* webpackChunkName: "BankAccount" */ "../views/Admin/BankAccount.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "employee-tracking",
        name: "Employee Tracking",
        component: () =>
          import(
            /* webpackChunkName: "employeeTracking" */ "../views/Admin/EmployeeTracking.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "employee-bulk-order",
        name: "Employee Bulk Order",
        component: () =>
          import(
            /* webpackChunkName: "employeeBulkOrder" */ "../views/Admin/EmployeeBulkOrder.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "balance-management",
        name: "Balance Management",
        component: () =>
          import(
            /* webpackChunkName: "balanceManagement" */ "../views/Admin/BalanceManagement.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "brands-management",
        name: "Brands Management",
        component: () =>
          import(
            /* webpackChunkName: "brandsManagement" */ "../views/Admin/BrandsManagement/Index.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "brand/:id",
        name: "Add Edit Brand",
        component: () =>
          import(
            /* webpackChunkName: "addEditBrand" */ "../views/Admin/BrandsManagement/AddEditBrand.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      },
      {
        path: "vendor/:id",
        name: "Add Edit Vendor",
        component: () =>
          import(
            /* webpackChunkName: "addEditVendor" */ "../views/Admin/BrandsManagement/AddEditVendor.vue"
          ),
        meta: { requiresAuth: true, role: "admin" },
      }
    ],
  },

  {
    path: "/humanResource",
    name: "Human Resource View",
    component: () =>
      import(
        /* webpackChunkName: "humanResourcePages" */ "../views/HumanResource/Index.vue"
      ),
    meta: { requiresAuth: true, role: "hr" },
    children: [
      {
        path: "dashboard",
        name: "HR Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "humanResourceDashboard" */ "../views/HumanResource/Dashboard.vue"
          ),
        meta: { requiresAuth: true, role: "hr" },
      },
      {
        path: "send-gift",
        name: "Send Gift",
        component: () =>
          import(
            /* webpackChunkName: "sendGift" */ "../views/HumanResource/SendGift.vue"
          ),
        meta: { requiresAuth: true, role: "hr" },
      },
      {
        path: "my-wallet",
        name: "My Wallet",
        component: () =>
          import(
            /* webpackChunkName: "myWallet" */ "../views/HumanResource/MyWallet.vue"
          ),
        meta: { requiresAuth: true, role: "hr" },
      },
      {
        path: "redemption-pipeline",
        name: "Redemption Pipeline",
        component: () =>
          import(
            /* webpackChunkName: "humanResourcePurchasePipeline" */ "../views/HumanResource/PurchasePipeline.vue"
          ),
        meta: { requiresAuth: true, role: "hr" },
      },
      {
        path: "employee-tracking",
        name: "Hr Employee Tracking",
        component: () =>
          import(
            /* webpackChunkName: "humanResourceEmployeeTracking" */ "../views/HumanResource/EmployeeTracking.vue"
          ),
        meta: { requiresAuth: true, role: "hr" },
      },
    ],
  },

  {
    path: "/employee",
    name: "Employee View",
    component: () =>
      import(
        /* webpackChunkName: "employeePages" */ "../views/Employee/Index.vue"
      ),
    meta: { requiresAuth: true, role: "employee" },
    children: [
      {
        path: "home",
        name: "Employee Home",
        component: () =>
          import(
            /* webpackChunkName: "employeeHome" */ "../views/Employee/Home.vue"
          ),
        meta: { requiresAuth: true, role: "employee" },
      },
      {
        path: "gift-redemption",
        name: "Gift Redemption",
        component: () =>
          import(
            /* webpackChunkName: "giftRedemption" */ "../views/Employee/GiftRedemption.vue"
          ),
        meta: { requiresAuth: true, role: "employee" },
      },
    ],
  },
];

const isUserAuthorized = (currentRoute, usertype) => {
  if (currentRoute.meta.role == usertype) {
    return true;
  } else {
    return false;
  }
};

const router = createRouter({
  history: createWebHistory(),
  // You are going to have to make an ENV File
  // history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated, e.g., by checking Local Storage.
    if (TokenService.getLocalAccessToken()) {
      // Check to see if the user is authorized.
      let currentUser = TokenService.getUser();
      if (isUserAuthorized(to, currentUser.user.type)) {
        next();
      } else {
        next("/page-not-found");
      }
    } else {
      // Redirect to the login page.
      // Call the Toaster Alert over Here.
      next("/login");
    }
  } else {
    // If the route does not require authentication, allow access.
    next();
  }
});

export default router;
